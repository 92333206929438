import React, { createRef, useEffect } from "react"
import PropTypes from "prop-types"
import lottie from "lottie-web"

export const Lottie = React.memo(({ animation, width = "100%", height="100%", className="", svgParentClassName="" }) => {
  let animationContainer = createRef()

  useEffect(() => {
    let settings = {}

    settings["animation"] = lottie.loadAnimation({
      container: animationContainer.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: animation,
    })
    settings["animation"].play()
    return () => settings["animation"].destroy()
  }, [animationContainer, animation])

  return (
    <div className={`flex ${className}`} style={{ width: width, height: height }}>
      <div ref={animationContainer} className={`outline-none ${svgParentClassName}`} />
    </div>
  )
})

Lottie.propTypes = {
  animation: PropTypes.object.isRequired,
  width: PropTypes.string,
}
