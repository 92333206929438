/*global gtag*/
import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { usePageSetup } from "@components/usePageSetup"
import { Layout } from "@components/Layout"
import { Seo } from "@components/Seo"
import { ButtonAction, Button } from "@components/Button"
import { splitText } from "@utils"
import { Lottie } from "@components/Lottie"
import techTalentWhitepaper from "@lottie/tech-talent-whitepaper.json"
import { GeneralForm } from "@components/Form"

const TechTalenWhitepaperPage = ({ data }) => {
  const { aboutJKImage } = data
  const { themeColor } = usePageSetup({
    themeColor: "bg-gray-darkest",
    navigationColor: "text-white",
    displayGeneralForm: false,
  })

  const downloadGuide = () => {
    const isBrowser = typeof window !== "undefined"
    if (!isBrowser) {
      return
    }
    gtag("event", "ungated_download", { event_category: "download", event_label: "tech_report" })
    window.open("/documents/jk-tech-talent-whitepaper.pdf", "_blank").focus()
  }

  return (
    <Layout>
      <Seo title="Tech Talent Whitepaper" description="" />
      <div className="overflow-hidden">
        <div className={themeColor} style={{ height: "94px" }}></div>
        <div className={themeColor}>
          <div className="container">
            <div className="flex justify-center">
              <div className="lg:w-10/12">
                <div className="pt-clamp-14-25 pb-clamp-8-16">
                  <h1
                    className="text-clamp-40-80"
                    data-aos="stagger"
                    dangerouslySetInnerHTML={{
                      __html: splitText({
                        "text-teal-light": "Free guide: Winning over former Big Tech employees ",
                        "text-white": "to corporate tech roles.",
                      }),
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="flex justify-center lg:pb-18">
              <div className="lg:w-10/12">
                <div className="flex flex-col-reverse grid-cols-10 lg:grid">
                  <div className="col-span-6 mt-14 lg:pr-18 lg:mt-0">
                    <div className="relative flex">
                      <div className="absolute inset-0 flex lg:hidden">
                        <div className="mt-auto aspect-w-667 aspect-h-80 bg-white-dark extend before:bg-white-dark after:bg-white-dark"></div>
                      </div>
                      <div data-aos="fade-right">
                        <Lottie animation={techTalentWhitepaper} />
                      </div>
                    </div>
                  </div>
                  <div className="col-span-3">
                    <div className="lg:pt-18" data-aos="fade-left">
                      <p className="text-white">
                        A data-driven guide to attracting and hiring newly available tech talent.
                      </p>
                      <div className="mt-10">
                        <ButtonAction func={downloadGuide} className="p-5 text-gray-darkest bg-teal-light">
                          Get the free guide
                        </ButtonAction>
                      </div>
                    </div>
                    r
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <main>
          <div className="container">
            <div className="flex justify-center py-clamp-17-28">
              <div className="grid-cols-10 lg:grid lg:w-10/12">
                <div data-aos="fade-up" className="col-span-8">
                  <h3 className="text-clamp-25-35">
                    Making your organization stand out to laid off Big Tech talent isn’t impossible—you just have to
                    tell your very best story.
                  </h3>
                  <p className="mt-12">And our original research shows that you have a great story to tell.</p>
                  <p>
                    This free guide dives into the numbers to reveal what truly matters to recently laid off Big Tech
                    talent. It’s full of actionable insights that you can use to craft a powerful story—one that
                    showcases the advantages your organization has to offer.
                  </p>
                  <div className="mt-10">
                    <ButtonAction func={downloadGuide} className="py-5 text-gray-darkest ">
                      Get the free guide
                    </ButtonAction>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <section>
            <div className="lg:container">
              <div className="flex justify-center">
                <div className="flex flex-col-reverse grid-cols-10 gap-x-4 lg:grid lg:w-10/12">
                  <div className="relative col-start-1 col-end-11 row-start-1 row-end-2 lg:mt-28">
                    <div className="w-full h-full bg-teal-light extend before:bg-teal-light"></div>
                  </div>
                  <div className="container relative col-span-6 col-start-2 row-start-1 row-end-2 lg:max-w-full lg:px-0 py-clamp-12-19 lg:mt-28">
                    <div className="absolute inset-0 grid grid-cols-12 lg:hidden">
                      <div className="col-span-12 sm:col-span-11 bg-teal-light extend before:bg-teal-light"></div>
                    </div>
                    <div data-aos="fade-right" className="relative sm:pr-18">
                      <h3 className="text-clamp-35-62">About JK.</h3>
                      <p className="mt-clamp-9-16">
                        JK is a women-owned, full-service creative agency with over 30 years of employee engagement
                        experience. We are expert storytellers who help companies of all kinds drive more meaningful
                        engagement with employees and candidates through inspired talent communications and distinctive
                        employer brands.
                      </p>
                      <p>
                        By blending empathy, insight, and a clear understanding of your goals, we deliver programs and
                        platforms that pull people in and help you attract and retain the talent your business needs to
                        be its very best.
                      </p>
                      <Button link="/about-us" className="text-gray-darkest mt-clamp-11-17">
                        Learn more
                      </Button>
                    </div>
                  </div>
                  <div className="container relative col-start-8 col-end-11 row-start-1 row-end-2 pr-0 lg:max-w-full lg:px-0">
                    <div className="absolute inset-0 grid grid-cols-12 lg:hidden">
                      <div className="col-span-12 mt-auto h-1/2 lg:hidden sm:col-span-11 bg-teal-light extend before:bg-teal-light"></div>
                    </div>
                    <div data-aos="fade-left" className="lg:min-w-204">
                      <GatsbyImage
                        className="relative"
                        objectFit="contain"
                        image={getImage(aboutJKImage)}
                        alt="About JK"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </div>
      <section className="pt-clamp-32-44">
        <div className="container flex xl:justify-center">
          <div className="xl:w-10/12">
            <div style={{ maxWidth: "365px" }}>
              <h2 data-aos="stagger" className="text-title">
                <span>Let’s</span> <span>chat.</span>
              </h2>
            </div>
          </div>
        </div>
        <div className="container mt-24">
          <div className="mx-auto xl:w-10/12">
            <GeneralForm subject="General Inquiry | JK Tech Talent" />
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default TechTalenWhitepaperPage

export const TechTalenWhitepaperQuery = graphql`
  query techTalentWhitepaperQuery {
    aboutJKImage: file(relativePath: { eq: "global/outside.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
  }
`
