const wrapSpanTag = (copy, line) => {
  if (line.includes("break")) {
    if (!copy["break"] === "") {
      return `<br>`
    }
    return `<br class="${copy["break"]}">`
  }
  return copy[line].split(" ").map((word) => `<span class="${line}">${word}</span> `)
}

export const splitText = (copy) => {
  return Object.keys(copy)
    .map((line) => [...wrapSpanTag(copy, line)])
    .reduce((arr, cur) => [...arr, ...cur], [])
    .join("")
}
